const kr = {
    LANG_ABOUT:'스토어 소개',
    LANG_CONTACT:'매장에 문의',
    LANG_HELP:'돕다',
    LANG_IN_THIS_STORE:'이 가게에서',
    LANG_ON_SELLSBUY:'판매중',
    LANG_FOLLOW:'따르다',
    LANG_UNFOLLOW:'팔로우 취소',
    LANG_CHAT:'채팅',
    LANG_PRODUCTS:'제품',
    LANG_COMPANY_VERIFY:'회사 확인',
    LANG_CHAT_PERFORMANCE:'채팅 성능',
    LANG_FOLLOWERS:'팔로워',
    LANG_RATINGS:'등급',
    LANG_JOINED:'가입',
    LANG_MONTHS_AGO:'몇달 전',
    LANG_HOME:'집',
    LANG_ALL_PRODUCTS:'모든 제품',
    LANG_CONTACT_SUPPLIERS:'공급업체에 문의',
    LANG_SEND_MESSAGE_TO_THIS_SUPPLIER:'이 공급자에게 메시지 보내기',
    LANG_NAME:'이름',
    LANG_EMAIL:'이메일',
    LANG_MOBILE:'이동하는',
    LANG_SUBJECT:'주제',
    LANG_MESSAGE:'메시지',
    LANG_SMALL_MSG:'메시지는 20-8000자 사이여야 합니다.',
    LANG_INSTRUCTION:'더 나은 인용을 위해 다음을 포함하십시오.',
    LANG_INSTRUCTION1:'더 나은 인용을 위해 다음을 포함하십시오.',
    LANG_INSTRUCTION2:'특별 요청(있는 경우)',
    LANG_SEND_ENQ:'문의 보내기',
    LANG_SUPPLIER:'공급업체',
    LANG_FREE:'무료',
    LANG_GOLD:'금',
    LANG_SILVER:'은',
    LANG_PLATINUM:'백금',
    LANG_SOCIAL_LINK:'소셜_링크',
    LANG_POPULAR_STORE_PRODUCTS : '인기상품',
    LANG_SHIPPING:'무료 배송',
    LANG_INSTRUCTION3:'$100 이상 주문',
    LANG_RETURN:'무료 반품',
    LANG_INSTRUCTION4:'30일 이내 반품',
    LANG_INSTRUCTION5:'100% 안전한 결제',
    LANG_SECURE_PAYMENT:'보안 결제',
    LANG_BEST_PEICE:'최고의 가격',
    LANG_GURANTEED_PRICE:'보장된 가격',
    LANG_STORE_PRODUCTS:'매장 제품',
    LANG_CATEGORIES:'카테고리',
    LANG_RECORD_NOT_FOUND:'레코드를 찾을 수 없음',
    LANG_INFORMATION:'정보',
    LANG_ABOUT_US:'회사 소개',
    LANG_FAQ:'자주하는 질문',
    LANG_T_AND_C:'이용약관',
    LANG_CONTACT_US:'문의하기',
    LANG_CUSTOMER_SERVICE:'고객 서비스',
    LANG_PAYMENT_METHODS:'지불 방법',
    LANG_MONEY_BACK:'환불',
    LANG_RETURNS:'보고',
    LANG_PRIVACY_POLICY:'개인 정보 정책',
    LANG_GET_IN_TOUCH:'연락하기',
    LANG_TEL:'전화',
    LANG_COPYRIGHT:'저작권',
    LANG_ALL_RIGHT_RESERVED:'판권 소유',

    LANG_FEEDBACK:'피드백',
    LANG_CUSTOMERFEEDBACK:'고객 피드백',
    LANG_FULLNAME:'성명',
    LANG_STAR:'별점 매기기',
    LANG_MEAAGE_DIC:'당신의 마음에 무엇입니까?',

    LANG_COLLABORATE_WITH_US:'우리와 협력',
    LANG_TERMS_OF_USE:'이용약관',
    LANG_SITEMAP:'사이트맵',
    
}                         

export default kr;