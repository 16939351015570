import {Routes,Route} from "react-router-dom";
import Home from './home';
import Products from './products';
import Contactus from './contactus';
import Aboutus from './aboutus';
import Footercms from './footercms';
import Feedback from './Feedback';


function RouteList(props) {

  return (
        <Routes>
            <Route path='/' element={<Home/>} />
            <Route path='/products' element={<Products/>} />
            <Route path='/contactus' element={<Contactus/>} />
            <Route path='/aboutus' element={<Aboutus/>} />
            <Route path='/footercms' element={<Footercms/>} />
            <Route path='/feedback' element={<Feedback/>} />

        </Routes>
  );
}

export default RouteList;
