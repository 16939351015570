import React ,{useState,useEffect}from 'react'
import Axios from 'axios';
import global from './config/global';
import Header from './includes/header';
import Footer from './includes/footer';
import { t } from 'i18next';

const Aboutus = (props) => {
    const [state,setState] = useState({
        vendorData:{},
        isLoading:true,
    });
    const [vendercompanyyear,setvendercompanyyear] = useState('');

	useEffect(()=>{
        fetchAboutus();
    },[]);
    
    const stateHandler = (key,value) =>{
        state[key] = value;
        setState({...state});
    }

  const fetchAboutus = () => {
        let hostname = window.location.hostname;
        let spliteHost = hostname.split('.');
        let domain = '';
        if(spliteHost.length === 3 && spliteHost[0] !== 'www')
        {
            domain = spliteHost[0];
        }
    
		let body = {
			domain:domain,
		}

		Axios.post(`${global.BASEURL}micro/fetchVendorData`,body).then((res) => {
			if (res.data.status === 200) {
				let response = res.data.data;
                stateHandler('vendorData',response);
                calculate_age(response.company_year)
			}
            stateHandler('isLoading',false)
		}).catch((err) => {
            stateHandler('isLoading',false)
			console.log(err);
		})

	}
  const calculate_age = (company_year) => {
    var current_year = new Date().getFullYear();
    var com_now = current_year - company_year;
    setvendercompanyyear(com_now);
  }
	
  return(
    <>
    {props.home !==true && <Header />}
        {state.isLoading && 
		<div className="preloader">
			<div className="preloader-inner">
				<div className="preloader-icon">
					<span></span>
					<span></span>
				</div>
			</div>
		</div>
		}
    <section className="pb-5 bg-light">
    <div className="container">
      <section className="mb-3 pt-2">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 company_profile_right_box">
              <div className="card mb-3">
                <div className="card-body">
                  <div className="row">
                      <div className="col-12 col-md-8">
                        <h5><span className="badge bg-dark fw-bold align-top">{vendercompanyyear}<small className="fw-bold fs-10">YRS</small></span> {state.vendorData.company_name}</h5>
                      </div>
                      <div className="col-12 col-md-4 text-end mb_display_none">
                        <a href='/contactus' type="button" className="btn btn-primary">{t('LANG_CONTACT_SUPPLIERS')}</a>
                      </div>
                  </div>
                </div>
              </div>
              <div className="card mb-3 company_over_view">
                <div className="card-body">
                  <div className="tab-content" id="myTabContent" dangerouslySetInnerHTML={{"__html":state.vendorData.store_aboutus}}></div>
                </div>
              </div>
              
              
            </div>
          </div>      
        </div>
      </section>
    </div>
  </section>
  {props.home !==true && <Footer />}
  </>
    );
}

export default Aboutus;
