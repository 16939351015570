let live = true;
let BASEURL = 'http://localhost:3001/vendor/v-1.0.0/';
let M_BASEURL = 'http://localhost:3001/micro/v-1.0.0/';
let W_BASEURL = 'http://localhost:3001/web/v-1.0.0/';
let PRODUCT_IMAGE = 'https://cloud.sellsbuy.com/product_images/';
let COMPANY_LOGO = 'https://cloud.sellsbuy.com/vender_company_logo/';
let MICROBANNER = 'https://cloud.sellsbuy.com/microsite_thumbnail/';
let qiiko_CHAT_URL ='https://messenger.sellsbuy.com';
let MESSENGER = 'http://localhost:3001/messanger/';
let A_BASEURL = 'http://localhost:3001/admin/v-1.0.0/';
let LOGO_PATH = 'https://cloud.sellsbuy.com/logofolder/';
let CURRENCY_SYB = '$';
let DEFAULT_CURCY = 'USD';

let siteurl = 'https://sellsbuy.com/'

let langList = [
    {label:'English',value:'en'},
    /* {label:'Hindi',value:'hn'}, */
    {label:'Chinese',value:'ce'},
    {label:'France',value:'fr'},
    {label:'Japanese',value:'jp'},
    {label:'Korean',value:'kr'},
    /* {label:'German',value:'gr'}, */
    {label:'Lao',value:'lao'},
    {label:'Thai',value:'th'},
]

let currencyList = [
    {label:'USD',value:'USD'},
    /*{label:'INR',value:'INR'},*/
    {label:'CNY',value:'CNY'},
    {label:'EUR',value:'EUR'},
    {label:'JPY',value:'JPY'},
    {label:'KRW',value:'KRW'},
    {label:'LAK',value:'LAK'},
    {label:'THB',value:'THB'},
]
let decrypt_key = 'AVhR7SAVhR7S3gduYGIgrNRAa3gduAVhR7S3gduAbZYGIgrNRAa3ZAbZduYGIgrNRAa3ZAbZ';


if(live)
{
    BASEURL = 'https://backend.sellsbuy.com/vendor/v-1.0.0/';
    M_BASEURL = 'https://backend.sellsbuy.com/micro/v-1.0.0/';
    W_BASEURL = 'https://backend.sellsbuy.com/web/v-1.0.0/';
    PRODUCT_IMAGE = 'https://cloud.sellsbuy.com/product_images/';
    COMPANY_LOGO = 'https://cloud.sellsbuy.com/vender_company_logo/';
    siteurl = 'https://sellsbuy.com/'
    MICROBANNER='https://cloud.sellsbuy.com/microsite_thumbnail/';
    qiiko_CHAT_URL = 'https://messenger.sellsbuy.com/chat-box/';
    MESSENGER = 'https://backend.sellsbuy.com/messanger/';
    A_BASEURL = 'https://backend.sellsbuy.com/admin/v-1.0.0/';
    
}

const global = {
    BASEURL : BASEURL,
    M_BASEURL:M_BASEURL,
    W_BASEURL : W_BASEURL,
    PRODUCT_IMAGE:PRODUCT_IMAGE,
    COMPANY_LOGO:COMPANY_LOGO,
    SITEURL:siteurl,
    MICROBANNER:MICROBANNER,
    MESSENGER:MESSENGER,
    langList:langList,
    qiiko_CHAT_URL:qiiko_CHAT_URL,
    CURRENCY_SYB:CURRENCY_SYB,
    DEFAULT_CURCY:DEFAULT_CURCY,
    currencyList:currencyList,
    decrypt_key:decrypt_key,
    A_BASEURL:A_BASEURL,
    LOGO_PATH:LOGO_PATH
}


export default global;
