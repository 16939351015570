import React, { useState, useEffect } from 'react'
import Header from './includes/header';
import Footer from './includes/footer';
import Products from './products';
import Axios from 'axios';
import global from './config/global';
import i18next, { t } from 'i18next';
import {convertCurrency} from './config/currency';



const Home = () => {
  const [state,setState] = useState({
    colors:{},
    productListData:{},
    isLoading:true,
    });
    const [getcurrencydata,setgetcurrencydata] = useState('');
  const [currencySyb,setcurrencySyb] = useState('');

  let hostname = window.location.hostname;
  let spliteHost = hostname.split('.');
  let domain = '';
  if(spliteHost.length === 3 && spliteHost[0] !== 'www')
  {
    domain = spliteHost[0];
  }
  useEffect(()=>{
    fetchColors(domain);
    fetchProductsData(domain);
  },[]);

  const stateHandler = (key,value) =>{
    state[key] = value;
    setState({...state});
}

const getProductPriceRange = (product_price) => {
  var minprice = 0;
  // var maxprice = 0;
  if(product_price)
  {
    for(let value of product_price)
    {
      let selling = value.price - (value.price * value.discount) / 100;
      if(minprice > selling || minprice == 0)
      {
        minprice = selling;
      }
    }
      }
      
  return convertCurrency(`${minprice.toFixed(2)}`,getcurrencydata);
}

  const fetchColors=(domain) =>{
		let body = {
			domain: domain,
		}
		Axios.post(`${global.BASEURL}micro/fetchMicroDataBysubdomain`, body).then((res) => {
      if (res.data.status === 200) {
				let response = res.data.data;
				stateHandler('colors',response)
			}
			else {
        stateHandler('isLoading',false)
      }
    }).catch((err) => {
    console.log(err);
    })
	}

  const fetchProductsData = (domain) => {
  let body = {
    domain:domain,
    defaultLang:i18next.language
    // cat_id:state.cat_id,
    // pro_name:state.pro_name
  }
  Axios.post(`${global.BASEURL}micro/fetchMicroSiteProducts`,body).then((res) => {
    if (res.data.status === 200) {
      let response = res.data.data;
              stateHandler('productListData',response)
    }
          stateHandler('isLoading',false)
  }).catch((err) => {
          stateHandler('isLoading',false)
    console.log(err);
  })

}

const getFirstOtherAttrId = (product) => {
  let other_pro_id = 0;
  var minprice = 0;
  if(product)
  {
    for(let value of product)
    {
      let selling = value.price - (value.price * value.discount) / 100;
      if(minprice > selling || minprice == 0)
      {
        other_pro_id = value.other_pro_id;
        minprice = selling;
      }
    }
  }
  
  return other_pro_id;
}
const truncate = (str) => {
  return str.length > 10 ? str.substring(0, 20) + "..." : str;
  }
  
  return (
   <>
   <Header get_data={(Syb,data)=>{
      setgetcurrencydata(data);
      setcurrencySyb(Syb);
   }} home={true} />
   <section className="">
    <div className="mic_slider_img">
      <div id="demo" className="carousel slide" data-bs-ride="carousel">

        <div className="carousel-indicators">
          <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active"></button>
          <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
          <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
        </div>
        
        
        <div className="carousel-inner">
          <div className="carousel-item active">
                <img src={`${global.MICROBANNER}${state.colors.banner}`} className="img-fluid" width='100%'  />
            </div>
          <div className="carousel-item">
          <img src={`${global.MICROBANNER}${state.colors.banner_2}`} className="img-fluid" width='100%' />
          </div>
          <div className="carousel-item">
          <img src={`${global.MICROBANNER}${state.colors.banner_3}`} className="img-fluid" width='100%' />
          </div>
        </div>
        
        
        <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
          <span className="carousel-control-prev-icon"></span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
          <span className="carousel-control-next-icon"></span>
        </button>
      </div>      
    </div>
  </section>
  <section className=" pt-5 pb-5 bg-light">
    <div className="container">
      <h5>{t('LANG_POPULAR_STORE_PRODUCTS')}</h5>
      <div className="row mic_prodcu_list g-2">
      {state.productListData.length>0 ?
    state.productListData.map((value,index)=>(
        <div key={index} className="col-6 col-md-2">
          <a target="_blank" href={`${global.SITEURL}productDetail/${value.prod_id}/${getFirstOtherAttrId(value.pricedata)}`}>
          <div className="card">
            <div className="card-body">
              <figure className="mb-2">
                <img src={`${global.PRODUCT_IMAGE}640X640/${value.product_image_path}`} alt={value.pro_name} className="img-fluid" />
              </figure>
              <p className="">
                {truncate(value.pro_name)}
              </p>
              <div className="d-flex justify-content-between">
                  <div className="p-2">
                    <h6 className="text-main">{currencySyb} {getProductPriceRange(value.pricedata)}</h6>
                  </div>
                  {/* <div className="p-2 ">
                    <small className="text-muted">2k Sold</small>
                  </div> */}
              </div>
            </div>
          </div>
          </a>
        </div>
  ))
  :
  <></>
}
      </div>
      <hr/>
      <Products syb={currencySyb} curr_data={getcurrencydata} home={true}/>
    </div>
  </section>



  <section className="mb-3 mic_footer_bar_1">
    <div className="container mt-4">
      <div className="row text-secondary">
        <div className="col-6 col-md-3">
          <div className="d-flex justify-content-center">
              <div className="p-2 pt-3 fs-1 "><i className="material-icons-outlined">local_mall</i></div>
              <div className="p-2 ">
                <h6>{t('LANG_SHIPPING')}</h6>
                <small>{t('LANG_INSTRUCTION3')}</small>
              </div>
          </div>
        </div>
        <div className="col-6 col-md-3">
          <div className="d-flex justify-content-center">
              <div className="p-2 pt-3 fs-1 "><i className="material-icons-outlined">restore</i></div>
              <div className="p-2 ">
                <h6>{t('LANG_RETURN')}</h6>
                <small>{t('LANG_INSTRUCTION4')}</small>
              </div>
          </div>
        </div>
        <div className="col-6 col-md-3">
          <div className="d-flex justify-content-center">
              <div className="p-2 pt-3 fs-1 "><i className="material-icons-outlined">shield</i></div>
              <div className="p-2 ">
                <h6>{t('LANG_SECURE_PAYMENT')}SUCURE PAYMENT</h6>
                <small>{t('LANG_INSTRUCTION5')}</small>
              </div>
          </div>
        </div>
        <div className="col-6 col-md-3">
          <div className="d-flex justify-content-center">
              <div className="p-2 pt-3 fs-1 "><i className="material-icons-outlined">favorite_border</i></div>
              <div className="p-2 ">
                <h6>{t('LANG_BEST_PEICE')}</h6>
                <small>{t('LANG_GURANTEED_PRICE')}</small>
              </div>
          </div>
        </div>
      </div>      
    </div>
  </section>
  <Footer/>
   </>
  );
}

export default Home;
