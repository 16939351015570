import React,{useState,useEffect} from 'react'
import Axios from 'axios';
import global from '../config/global';
import { t } from 'i18next';

function Footer()
{
	const [	state,setState] = useState({
		social:{}
	});
	const [	colors,setcolors] = useState({});
	const [	cmstype,setcmstype] = useState({});
    const [	company_name,setcompany_name] = useState();

	useEffect(()=>{
		fetchVendorData()
		fetchColors()
	},[]);
	
	let hostname = window.location.hostname;
	let spliteHost = hostname.split('.');
	let domain = '';
	if(spliteHost.length === 3 && spliteHost[0] !== 'www')
	{
	  domain = spliteHost[0];
	}


	const fetchColors = () =>
	{
        let body = {
            domain:domain,
        }
        Axios.post(`${global.BASEURL}micro/fetchMicroDataBysubdomain`,body).then((res) => {
            if (res.data.status === 200) {
                let response = res.data.data;
				setcolors(response)
            }
        }).catch((err) => {
            console.log(err);
        })
	}

	const fetchVendorData = (index) => {
		let body = {
			domain:domain,
		}
		Axios.post(`${global.BASEURL}micro/fetchVendorData`,body).then((res) => {
			if (res.data.status === 200) {
				let response = res.data.data;
                setState(response)
                setcompany_name(response.company_name)
				let vendor_id = res.data.data.vender_id;
				fetchfooterdata(vendor_id)
			}
		}).catch((err) => {
			console.log(err);
		})

	}
	const fetchfooterdata = (vender_id) =>
	{
		let body = {
			id:vender_id,
            type:'Detail'
        }
        Axios.post(`${global.M_BASEURL}micro/fetchfooterdata`,body).then((res) => {
			if (res.data.status === 200) {
                let response = res.data.data[0];
                setcmstype(response)
            }
        }).catch((err) => {
            console.log(err);
        })
	}
	return(
        <>
        <footer className="">
    <section className="footer_2 p-3" style={{backgroundColor:colors.color2}}>
        <div className="container">
            <div className="row">
                <div className="col-6 col-md-5">
                    <dl>
                    <div dangerouslySetInnerHTML={{ __html: cmstype.Detail }}></div>
                    </dl>
                </div>
                <div className="col-6 col-md-2">
                    <dl>
                        <dt>{t('LANG_INFORMATION')}</dt>
                        <dd><a href="/aboutus" className="text-white" style={{fontSize:'0.875em'}}>{t('LANG_ABOUT_US')}</a></dd>
                        <dd><a href="/footercms?type=Faq" className="text-white" style={{fontSize:'0.875em'}}>{t('LANG_FAQ')}</a></dd>
                        <dd><a href="/footercms?type=Terms_Conditions" className="text-white" style={{fontSize:'0.875em'}}>{t('LANG_T_AND_C')}</a></dd>
                        <dd><a href="/contactus" className="text-white" style={{fontSize:'0.875em'}}>{t('LANG_CONTACT_US')}</a></dd>
                    </dl>
                </div>
                <div className="col-6 col-md-2">
                    <dl>
                        <dt>{t('LANG_CUSTOMER_SERVICE')}</dt>
                        <dd><a href="/footercms?type=Payment_Methods" className="text-white" style={{fontSize:'0.875em'}}>{t('LANG_PAYMENT_METHODS')}</a></dd>
                        <dd><a href="/footercms?type=Money_back" className="text-white" style={{fontSize:'0.875em'}}>{t('LANG_MONEY_BACK')}</a></dd>
                        <dd><a href="/footercms?type=Returns" className="text-white" style={{fontSize:'0.875em'}}>{t('LANG_RETURNS')}</a></dd>
                        <dd><a href="/footercms?type=Shipping" className="text-white" style={{fontSize:'0.875em'}}>{t('LANG_SHIPPING')}</a></dd>
                        <dd><a href="/footercms?type=Privacy_Policy" className="text-white" style={{fontSize:'0.875em'}}>{t('LANG_PRIVACY_POLICY')}</a></dd>
                    </dl>
                </div>
                <div className="col-12 col-md-3">
                    <dl>
                        <dt>{t('LANG_GET_IN_TOUCH')}</dt>
                        <h6>{state.company_name}</h6>
                        <p>{state.address}</p>
                        <p>{state.website_url}</p>
                        <p>{t('LANG_EMAIL')} : {state.email}</p>
                        <p>{t('LANG_TEL')} : {state.mobile}</p>
                        
                    </dl>
                </div>
            </div>

            <hr/>
            <section>
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="text-center">
							<p>
                                <a href="https://sellsbuy.com/" target='_blank' style={{fontSize:'0.875em',marginRight:'5px'}} className='p-2'>{t("LANG_HOME")}</a>|
                                <a href="https://support.sellsbuy.com/about-us/" target='_blank' style={{fontSize:'0.875em',marginRight:'5px'}} className='p-2'>{t('LANG_ABOUT_US')}</a>|
                                <a href="https://www.sellsbuy.com/contactus" target='_blank' style={{fontSize:'0.875em',marginRight:'5px'}} className='p-2'>{t("LANG_COLLABORATE_WITH_US")}</a>|
								<a href="https://support.sellsbuy.com/terms-of-use/" target='_blank' style={{fontSize:'0.875em',marginRight:'5px',marginLeft:'5px'}} className='p-2'>{t('LANG_TERMS_OF_USE')}</a>|
								<a href="https://support.sellsbuy.com/privacy-policy/" target='_blank' style={{fontSize:'0.875em',marginRight:'5px',marginLeft:'5px'}} className='p-2'>{t('LANG_PRIVACY_POLICY')}</a>|
                                <a href="https://support.sellsbuy.com/sitemap/" target='_blank' style={{fontSize:'0.875em',marginRight:'5px',marginLeft:'5px'}} className='p-2'>{t('LANG_SITEMAP')}</a>|
							    <span style={{fontSize:'0.875em',marginRight:'5px',marginLeft:'5px'}} >	{t('LANG_COPYRIGHT')} © <a href="https://www.sellsbuy.com" target="_blank" style={{fontSize:'0.999em',fontWeight:'700'}}>Sellsbuy</a>  {t('LANG_ALL_RIGHT_RESERVED')}. </span></p>
						</div>
                    </div>
                </div>
            </section>
            
        </div>
    </section>
    <section className="bg-white">
        <div className="container">
            <div className="footer_links text-center">
                
                <span style={{fontSize:'0.875em'}}>{t('LANG_COPYRIGHT')} @ 2022 <a href='' style={{fontSize:'0.999em',fontWeight:'700'}}> {company_name} </a> {t('LANG_ALL_RIGHT_RESERVED')}. </span>
            </div>
        </div>
    </section>
</footer>
<div className="footer d-md-none d-lg-none d-xl-none d-xxl-none d-sm-block">  
            <div className="no-gutters">
                <div className="col-auto mx-auto">
                    <div className="row no-gutters justify-content-center">
                        <div className="col-auto">
                            <a href="/" className="btn btn-link-default active">
                                <i className="material-icons">home</i>
                            </a>
                                <small className='p-1'>Home</small>
                        </div>
                        <div className="col-auto">
                            <a href="/products" className="btn btn-link-default">
                                <i className="material-icons">local_mall</i>
                            </a>
                            <small className='p-1'>Products</small>
                        </div>
                        <div className="col-auto">
                            <a href="/aboutus" className="btn btn-link-default">
                                <i className="material-icons">account_circle</i>
                            </a>
                            <small className='p-1'>About</small>
                        </div>
                        <div className="col-auto">
                            <a href="/contactus" className="btn btn-link-default">
                                <i className="material-icons">email</i>
                            </a>
                            <small className='p-1'>Contact</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
</>

    );
	
}
export default Footer;
