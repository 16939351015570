const initialState = {
    userid:null,
};
const loggedReducer=(state=initialState,action)=>{
    switch(action.type){
      case 'USERID':
        return {
            userid:action.payload
        }
        default:
            return state;
    }
  }
export default loggedReducer;