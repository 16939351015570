const jp = { 
    LANG_ABOUT:'ストアについて',
    LANG_CONTACT:'お問い合わせ先',
    LANG_HELP:'ヘルプ',
    LANG_IN_THIS_STORE:'この店では',
    LANG_ON_SELLSBUY:'Sellsbuyで',
    LANG_FOLLOW:'従う',
    LANG_UNFOLLOW:'フォローをやめる',
    LANG_CHAT:'チャット',
    LANG_PRODUCTS:'製品',
    LANG_COMPANY_VERIFY:'会社の確認',
    LANG_CHAT_PERFORMANCE:'チャットパフォーマンス',
    LANG_FOLLOWERS:'フォロワー',
    LANG_RATINGS:'評価',
    LANG_JOINED:'参加しました',
    LANG_MONTHS_AGO:'数か月前',
    LANG_HOME:'家',
    LANG_ALL_PRODUCTS:'すべての製品',
    LANG_CONTACT_SUPPLIERS:'サプライヤーに連絡する',
    LANG_SEND_MESSAGE_TO_THIS_SUPPLIER:'このサプライヤーにメッセージを送信',
    LANG_NAME:'名前',
    LANG_EMAIL:'Eメール',
    LANG_MOBILE:'モバイル',
    LANG_SUBJECT:'主題',
    LANG_MESSAGE:'メッセージ',
    LANG_SMALL_MSG:'メッセージは 20 ～ 8000 文字にする必要があります',
    LANG_INSTRUCTION:'より良い引用のために、含める',
    LANG_INSTRUCTION1:'より良い引用のために、含める',
    LANG_INSTRUCTION2:'特別なリクエストがある場合',
    LANG_SEND_ENQ:'お問い合わせを送信',
    LANG_SUPPLIER:'サプライヤー',
    LANG_FREE:'無料',
    LANG_GOLD:'金',
    LANG_SILVER:'銀',
    LANG_PLATINUM:'白金',
    LANG_SOCIAL_LINK:'ソーシャルリンク',
    LANG_POPULAR_STORE_PRODUCTS : '人気店の商品',
    LANG_SHIPPING:'送料無料',
    LANG_INSTRUCTION3:'100ドル以上の注文',
    LANG_RETURN:'無料返品',
    LANG_INSTRUCTION4:'30日以内の返品',
    LANG_INSTRUCTION5:'100% 安全な支払い',
    LANG_SECURE_PAYMENT:'安全なお支払い',
    LANG_BEST_PEICE:'ベストプライス',
    LANG_GURANTEED_PRICE:'保証価格',
    LANG_STORE_PRODUCTS:'ストア製品',
    LANG_CATEGORIES:'カテゴリー',
    LANG_RECORD_NOT_FOUND:'記録が見当たりませんでした',
    LANG_INFORMATION:'情報',
    LANG_ABOUT_US:'私たちに関しては',
    LANG_FAQ:'よくある質問',
    LANG_T_AND_C:'利用規約',
    LANG_CONTACT_US:'お問い合わせ',
    LANG_CUSTOMER_SERVICE:'顧客サービス',
    LANG_PAYMENT_METHODS:'お支払い方法',
    LANG_MONEY_BACK:'返金',
    LANG_RETURNS:'戻り値',
    LANG_PRIVACY_POLICY:'プライバシーポリシー',
    LANG_GET_IN_TOUCH:'連絡を取る',
    LANG_TEL:'電話',
    LANG_COPYRIGHT:'著作権',
    LANG_ALL_RIGHT_RESERVED:'全著作権所有',

    LANG_FEEDBACK:'フィードバック',
    LANG_CUSTOMERFEEDBACK:'お客様の声',
    LANG_FULLNAME:'あなたのフルネーム',
    LANG_STAR:'星評価',
    LANG_MEAAGE_DIC:'何を考えているの？',

    LANG_COLLABORATE_WITH_US:'私たちと協力してください',
    LANG_TERMS_OF_USE:'利用規約',
    LANG_SITEMAP:'サイトマップ',
    
}                         

export default jp;