import React, { useState, useEffect } from 'react'
import Axios from 'axios';
import global from './config/global';
import Header from './includes/header';
import Footer from './includes/footer';
import i18next, { t } from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faStar} from '@fortawesome/free-solid-svg-icons';
import Select, { components } from 'react-select';
const { Option } = components;

const Feedback = (props) => {
    const[isLoading,setisLoading]=useState(true);
    const[name,setname]=useState('');
    const[star,setstar]=useState('');
    const[comment,setcomment]=useState('');
    const[vendordata,setvendordata]=useState({});
    
    useEffect(() => {
        let hostname = window.location.hostname;
        let spliteHost = hostname.split('.');
        let domain = '';
        if (spliteHost.length === 3 && spliteHost[0] !== 'www') {
            domain = spliteHost[0];
        }
        fetchVendorData(domain)
    }, []);
    const options = [
        { value: '5', label: 'Best' },
        { value: '4', label: 'Better' },
        { value: '3', label: 'Good' },
        { value: '2', label: 'Poor' },
        { value: '1', label: 'Very Poor' },
    ]

    const customeOptions = props => (
        <Option {...props}>
            {Array(parseInt(props.value)).fill().map(()=>(
                <FontAwesomeIcon icon={faStar} color="red" />
            ))}
          {props.data.label}
        </Option>
    )
    const fetchVendorData = (domain) => {

        let body = {
          domain: domain,
        }
        Axios.post(`${global.BASEURL}micro/fetchVendorData`, body).then((res) => {
          if (res.data.status === 200) {
            let response = res.data.data;
            setvendordata(response)
          }
        }).catch((err) => {
          console.log(err);
        })
      }
    const submitfeedback=()=>{
        if(name==='' || name===undefined){
            alert('Name Is Required');
        }
        else{
            let body = {
                vendorId: vendordata.id,
                name: name,
                rating: star,
                comment: comment
                };
                Axios.post(global.BASEURL + "users/submitfeedback",body).then((res) => {
                if (res.data.status === 200) {
                    alert('Feedback Successfully Submitted !!')    
                }
                }).catch((err) => {
                    console.log(err);
                })
        }
    }



    return (
        <>
            <Header />
            <section className='p-5'>
            <div role="tabpanel" className="tab-pane active" id="feedback_form">
                <div className="row">
                    <div className="col-md-9">
                        <h4><strong>{t('LANG_CUSTOMERFEEDBACK')}</strong></h4>
                        <FontAwesomeIcon icon={"star"} color="red" />
                    </div>
                </div>
                <div className="panel panel-default">
                    <div className="panel-body">
                        <div>
                            <div className="row">
                                <div className="col-lg-6 ">
                                    <div className="form-group">
                                        <label for="usr">{t('LANG_NAME')} :</label>
                                        <input onChange={({target})=>{
                                            setname(target.value)
                                        }} type="text" className="form-control" placeholder={t('LANG_FULLNAME')} required="required" />
                                    </div>
                                </div>
                                <div className="col-lg-6 ">
                                    <div className="form-group">
                                        <label for="sel1">{t('LANG_STAR')} :</label>
                                        <Select
                                            defaultValue={{ label: 'best', value: 5 }}
                                            options={options}
                                            components={{ Option: customeOptions }}
                                            onChange={({value})=>{
                                                setstar(value)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label for="comment">{t('LANG_MESSAGE')} :</label>
                                <textarea className="form-control" onChange={({target})=>{
                                            setcomment(target.value)
                                        }} rows="5" placeholder={t('LANG_MEAAGE_DIC')} required="required"></textarea>
                            </div>
                            <div>
                                <button className="btn btn-info" onClick={()=>submitfeedback()}> Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
            <Footer />
        </>
    );
}

export default Feedback;
