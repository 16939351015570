const ce = {
    LANG_ABOUT:'关于商店',
    LANG_CONTACT:'联系商店',
    LANG_HELP:'帮助',
    LANG_IN_THIS_STORE:'在这家店',
    LANG_ON_SELLSBUY:'卖买',
    LANG_FOLLOW:'跟随',
    LANG_UNFOLLOW:'取消关注',
    LANG_CHAT:'聊天',
    LANG_PRODUCTS:'产品',
    LANG_COMPANY_VERIFY:'公司验证',
    LANG_CHAT_PERFORMANCE:'聊天表现',
    LANG_FOLLOWERS:'追随者',
    LANG_RATINGS:'收视率',
    LANG_JOINED:'已加入',
    LANG_MONTHS_AGO:'几个月前',
    LANG_HOME:'家',
    LANG_ALL_PRODUCTS:'所有产品',
    LANG_CONTACT_SUPPLIERS:'联系供应商',
    LANG_SEND_MESSAGE_TO_THIS_SUPPLIER:'将您的信息发送给该供应商',
    LANG_NAME:'姓名',
    LANG_EMAIL:'电子邮件',
    LANG_MOBILE:'移动的',
    LANG_SUBJECT:'主题',
    LANG_MESSAGE:'信息',
    LANG_SMALL_MSG:'您的信息必须介于 20-8000 个字符之间',
    LANG_INSTRUCTION:'为了更好的报价，包括',
    LANG_INSTRUCTION1:'为了更好的报价，包括',
    LANG_INSTRUCTION2:'特殊要求（如有）',
    LANG_SEND_ENQ:'发送询问',
    LANG_SUPPLIER:'供应商',
    LANG_FREE:'自由的',
    LANG_GOLD:'金子',
    LANG_SILVER:'银',
    LANG_PLATINUM:'铂',
    LANG_SOCIAL_LINK:'社交链接',
    LANG_POPULAR_STORE_PRODUCTS : '人气店铺产品',
    LANG_SHIPPING:'免运费',
    LANG_INSTRUCTION3:'100 美元以上的订单',
    LANG_RETURN:'免费退货',
    LANG_INSTRUCTION4:'30天内退货',
    LANG_INSTRUCTION5:'100% 安全支付',
    LANG_SECURE_PAYMENT:'安全付款',
    LANG_BEST_PEICE:'最好的价钱',
    LANG_GURANTEED_PRICE:'保证价格',
    LANG_STORE_PRODUCTS:'商店产品',
    LANG_CATEGORIES:'类别',
    LANG_RECORD_NOT_FOUND:'未发现记录',
    LANG_INFORMATION:'信息',
    LANG_ABOUT_US:'关于我们',
    LANG_FAQ:'常问问题',
    LANG_T_AND_C:'条款和条件',
    LANG_CONTACT_US:'联系我们',
    LANG_CUSTOMER_SERVICE:'客户服务',
    LANG_PAYMENT_METHODS:'支付方式',
    LANG_MONEY_BACK:'退款',
    LANG_RETURNS:'退货',
    LANG_PRIVACY_POLICY:'隐私政策',
    LANG_GET_IN_TOUCH:'取得联系',
    LANG_TEL:'电话',
    LANG_COPYRIGHT:'版权',
    LANG_ALL_RIGHT_RESERVED:'版权所有',
    LANG_FEEDBACK:'反馈',
    LANG_CUSTOMERFEEDBACK:'客户反馈',
    LANG_FULLNAME:'你的全名',
    LANG_STAR:'星级',
    LANG_MEAAGE_DIC:'你在想什么？',

    
}                         

export default ce;