import Axios from 'axios';
import crypto from "crypto-js";
import global from "./global.js";

const Currency = async(Code) =>{
  const data = Code;
  let date = new Date();
  date = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`;
  
  if(date !== data.date)
  {
    
    let currencydata = await Axios.get(`https://open.er-api.com/v6/latest/${global.DEFAULT_CURCY}`);
    if(currencydata.data.rates)
    {
      let currncyCode = data;
      if(currncyCode === undefined)
      {
        currncyCode = global.DEFAULT_CURCY;
      }
      
      let price = parseFloat(currencydata.data.rates[currncyCode]);
      if(price)
      {
        let encryptedValue = crypto.AES.encrypt(`${price}`,global.decrypt_key).toString();
        return {'Code':currncyCode,'date':date,'value':encryptedValue};
      }
    }
  }
}

const convertCurrency = (price,currencydata) =>{
  let currency = currencydata.Code;
  let value = currencydata.value;
  if(currency && value)
  {
    let decryptedVal = crypto.AES.decrypt(`${value}`,global.decrypt_key).toString(crypto.enc.Utf8);
    
    let newPrice = parseFloat(price) * parseFloat(decryptedVal);
    return newPrice.toFixed(2);
  }
  else{
    return (price)?parseFloat(price).toFixed(2):price;
  }
}

export {Currency,convertCurrency};