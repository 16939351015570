import React ,{useState,useEffect}from 'react'
import Axios from 'axios';
import global from './config/global';

import Header from './includes/header';
import Footer from './includes/footer';
import queryString from 'query-string';
import { t } from 'i18next';

const Contactus = (props) => {
    
    
    let hostname = window.location.hostname;
    let spliteHost = hostname.split('.');
    let domain = '';
    if(spliteHost.length === 3 && spliteHost[0] !== 'www')
    {
        domain = spliteHost[0];
    }
    const [name,setName] = useState('');
    const [email,setEmail] = useState('');
    const [mobile,setMobile] = useState('');
    const [subject,setSubject] = useState('');
    const [message,setMessage] = useState('');
   
    const [name_error,setNameError] = useState('');
    const [email_error,setEmailError] = useState('');
    const [mobile_error,setMobileError] = useState('');
    const [subject_error,setSubjectError] = useState('');
    const [message_error,setMessageError] = useState('');

    const [data,setData] = useState('');
    const [vendordata,setvendordata] = useState('');

    const [isLoading,setLoading] = useState(true);
	const [	colors,setcolors] = useState({});
  const [vendercompanyyear,setvendercompanyyear] = useState('');

    useEffect(() => { 
 
    if(props.location)
    {
        var params = queryString.parse(props.location.search); 
        setSubject(params.pro_name);
        setData(params);
    }
    fetchColors()
    fetchVendorData(domain)
  }, [],);

    const fetchColors = () =>
	{
        let body = {
            domain:domain,
        }
        Axios.post(`${global.BASEURL}micro/fetchMicroDataBysubdomain`,body).then((res) => {
            if (res.data.status === 200) {
                let response = res.data.data;
                setcolors(response)
            }
            setLoading(false);
        }).catch((err) => {
            setLoading(false);

            console.log(err);
        })
	}

    const fetchVendorData = (domain) => {

		let body = {
			domain: domain,
		}
		Axios.post(`${global.BASEURL}micro/fetchVendorData`, body).then((res) => {
            if (res.data.status === 200) {
				let response = res.data.data;
				setvendordata(response)
        calculate_age(response.company_year)
			}
		}).catch((err) => {
			console.log(err);
		})

	}
    
    const addinq = async ()=>{
        if(validation())
        {
            let body = {
                snd_user_id:1,
                sender_name:name,
                rec_user_id:colors.vendor_id,
                phone:mobile,
                email:email,
                subject:subject,
                message:message,
                pro_id:0,
                domain:domain
            };
            setLoading(true);
            Axios.post(global.M_BASEURL+'micro/addinq',body).then((res)=>{
                setLoading(false);
                
                if(res.data.status==200)
                {
                    alert('Message Send Successfully.....');
                }
                else
                {
                    alert('You Are Blocked By Vendor.....');
                }
                window.location.reload();
            }).catch((err)=>{
                setLoading(false);
                console.log(err);
            });   
        }
    }
    /**validation function */
    const validation = ()=>{
        setNameError('');
        setEmailError('');
        setMobileError('');
        setSubjectError('');
        setMessageError('');
        if(name=='')
        {
            setNameError('Please Enter Name');
            return false;
        }
        else if(email=='')
        {
            setEmailError('Please Enter Email');
            return false;
        }
        else if(mobile=='')
        {
            setMobileError('Please Enter Mobile');
            return false;
        }
        else if(subject=='')
        {
            setSubjectError('Please Enter Subject');
            return false;
        }
        else if(message=='')
        {
            setMessageError('Please Enter Message');
            return false;
        }
        else
        {
            return true;
        }
    }
    const calculate_age = (company_year) => {
      var current_year = new Date().getFullYear();
      var com_now = current_year - company_year;
      setvendercompanyyear(com_now);
    }
    
  return(
      <>
        <Header />
        <section className="pb-5 bg-light">
    <div className="container pt-3">
      <div className="row">
          <div className="col-12 col-md-9">
            <section className="mb-3">
              <div className="contact_us">
                <div className="card">
                  <div className="card-body">
                    <h4>{t('LANG_SEND_MESSAGE_TO_THIS_SUPPLIER')}</h4>
                    <div className="p-5">
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <table className="table table-borderless ">
                                  <tbody>
                                    <tr>
                                        <td className="w-10 text-end mt-2">{t('LANG_NAME')}<span className='text-danger'>*</span></td>
                                        <td colSpan="2">
                                          <div className="">
                                            <input type="text" className="form-control" value={name} onChange={({target})=>{setName(target.value)}} name="name" placeholder="Enter your Name" />
                                            <span style={{'color':"red"}}>{name_error}</span>
                                          </div>
                                        </td>
                                    </tr>
                                    <tr>
                                      <td className="w-10 text-end">{t('LANG_EMAIL')}<span className='text-danger'>*</span></td>
                                      <td colSpan="2">
                                        <div className="">
                                          <input value={email} onChange={({target})=>{setEmail(target.value)}} name="email" type="email"  className="form-control" placeholder="Enter your Email" />
                                          <span style={{'color':"red"}}>{email_error}</span>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="w-10 text-end">{t('LANG_MOBILE')}<span className='text-danger'>*</span></td>
                                      <td colSpan="2">
                                        <div className="">
                                          <input value={mobile} onChange={({target})=>{setMobile(target.value)}} name="mobilw" className="form-control"  placeholder="Enter your Mobile number" />
                                          <span style={{'color':"red"}}>{mobile_error}</span>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="w-10 text-end">{t('LANG_SUBJECT')}<span className='text-danger'>*</span></td>
                                      <td colSpan="2">
                                        <div className="">
                                          <input value={subject} onChange={({target})=>{setSubject(target.value)}} name="subject" className="form-control" placeholder="Enter your Subject" />
                                          <span style={{'color':"red"}}>{subject_error}</span>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                        <td className=" text-end">{t('LANG_MESSAGE')}<span className='text-danger'>*</span></td>
                                        <td><textarea className="form-control" rows="10" name="message" onChange={({target})=>{setMessage(target.value)}} placeholder="Enter your inquiry details such as product name, color, size, MOQ, FOB, etc." defaultValue={message}></textarea>    <br/>
                                            <small className="text-muted">{t('LANG_SMALL_MSG')}</small> <br/>
                                            <span style={{'color':"red"}}>{message_error}</span>
                                            </td><br/>
                                        <td className="w-25 mb_display_none">
                                            <div className="card bg-light">
                                              <div className="card-body">
                                              {t('LANG_INSTRUCTION')}:<br/>
                                                - {t('LANG_INSTRUCTION1')}<br/>
                                                - {t('LANG_INSTRUCTION2')}
                                              </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=" text-end"></td>
                                        <td colSpan="2">
                                            {/* <div className="mb-3">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked />
                                                    <label className="form-check-label">I agree to share my details to the supplier.</label>
                                                </div>
                                            </div> */}
                                            <button type="button" onClick={()=>{ addinq() }}className="btn btn-primary">{t('LANG_SEND_ENQ')}</button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                        </div>
                        
                    </div>
                  </div>
                </div>     
              </div>
            </section>
          </div>
          <div className="col-12 col-md-3">
          <div className="card shadow-sm border-1">
                <div className="card-body comp_card">
                <div>
                    <figure className="product_detail_box_img text-center">
                        <img src={`${global.COMPANY_LOGO}/${vendordata.logo}`} className="img-fluid"/>
                    </figure>
                </div>
                    <div className="product_listing_comp_info">
                    <div className="p-2 ">
                        <h4 className="mb-2">{vendordata.company_name}</h4>
                        <div className="d-flex">
                            <div className="pt-2 pb-1"><i className="material-icons text-main">explore</i></div>
                            <div className="p-2 ">{vendordata.city_name} {vendordata.state_name} {vendordata.country_name}</div>
                        </div>
                        <div className="d-flex">
                            <div className="pt-2 pb-1"><i className="material-icons text-main">call</i></div>
                            <div className="p-2 "><a href="">{vendordata.mobile}</a></div>
                        </div>
                        <div className="d-flex">
                            <div className="pt-2 pb-1"><i className="material-icons text-main">email</i></div>
                            <div className="p-2 "><a href="">{vendordata.email}</a></div>
                        </div>
                        {/* <div className="d-flex">
                            <div className="pt-2 pb-1"><i className="material-icons text-main">language</i></div>
                            <div className="p-2 "><a href="">www.gmydomain.com</a></div>
                        </div> */}
                        <div>
                            <span className="badge rounded-pill bg-light text-dark"><i className="material-icons text-primary fs-12">verified</i> Verified</span> 
                            <span className="badge rounded-pill bg-light text-dark"><span className="fw-bold"><img src="https://i.imgur.com/QpaQllR.png" /> {vendercompanyyear}</span> YRS</span>
                            { vendordata.package_type==="V_F" ?
                            <span className="badge rounded-pill bg-light text-dark">{t('LANG_FREE')} {t('LANG_SUPPLIER')}</span>
                            :
                            vendordata.package_type==="V_G" ?
                            <span className="badge rounded-pill bg-warning text-dark">{t('LANG_GOLD')} {t('LANG_SUPPLIER')}</span>
                            :
                            vendordata.package_type==="V_S" ?
                            <span className="badge rounded-pill bg-warning text-dark">{t('LANG_SILVER')} {t('LANG_SUPPLIER')}</span>
                            :
                            vendordata.package_type==="V_P" ?
                            <span className="badge rounded-pill bg-warning text-dark">{t('LANG_PLATINUM')} {t('LANG_SUPPLIER')}</span>
                            :
                            <></>
                      }
                        </div>
                        {/* <hr/> */}
                        {/* <iframe frameborder="0" scrolling="no" marginheight="0" marginwidth="0" width="100%" height="300" src="https://maps.google.com/maps?hl=en&amp;q=Delhi Cantonment, New Delhi, Delhi&amp;ie=UTF8&amp;t=roadmap&amp;z=15&amp;iwloc=B&amp;output=embed"></iframe> */}
                    </div>
                    </div>
                </div>
            </div>
            <div className="card mt-3">
              <div className="card-body">
                <h6>{t('LANG_SOCIAL_LINK')}</h6>
                <section>
                {vendordata.social?.facebook_onoff=='Y' && 
                  <div className="d-flex ">
                    <div className="p-2">
                    <i className="fa fs-30 fa-facebook" aria-hidden="true"></i>
                    </div>
                    <div className="p-2 ">
                    <p>+{vendordata.social?.facebook_info}</p>
                    </div>
                  </div>
                  }
                  {vendordata.social?.whatsapp_onoff=='Y' &&
                  <div className="d-flex ">
                    <div className="p-2">
                    <i className="fa fs-30 fa-whatsapp" aria-hidden="true"></i>
                    </div>
                    <div className="p-2 ">
                    <p>+{vendordata.social?.whatsapp_info}</p>
                    </div>
                  </div>
                  }
                  {vendordata.social?.skype=='Y' &&
                  <div className="d-flex ">
                    <div className="p-2">
                    <i className="fa fs-30 fa-twitter" aria-hidden="true"></i>
                    </div>
                    <div className="p-2 ">
                    <p>+{vendordata.social?.skype_info}</p>
                    </div>
                  </div>
                  }
                </section>
              </div>
            </div>
          </div>
      </div>
      
    </div>
  </section>
        <Footer />
    </>
    );
}

export default Contactus;
