const th = {
    LANG_ABOUT:'เกี่ยวกับร้านค้า',
    LANG_CONTACT:'ติดต่อร้านค้า',
    LANG_HELP:'ช่วย',
    LANG_IN_THIS_STORE:'ໃນຮ້ານນີ້',
    LANG_ON_SELLSBUY:'ในการขายซื้อ',
    LANG_FOLLOW:'ติดตาม',
    LANG_UNFOLLOW:'เลิกติดตาม',
    LANG_CHAT:'แชท',
    LANG_PRODUCTS:'สินค้า',
    LANG_COMPANY_VERIFY:'ยืนยันบริษัท',
    LANG_CHAT_PERFORMANCE:'ประสิทธิภาพการแชท',
    LANG_FOLLOWERS:'ผู้ติดตาม',
    LANG_RATINGS:'คะแนน',
    LANG_JOINED:'เข้าร่วม',
    LANG_MONTHS_AGO:'หลายเดือนก่อน',
    LANG_HOME:'บ้าน',
    LANG_ALL_PRODUCTS:'สินค้าทั้งหมด',
    LANG_CONTACT_SUPPLIERS:'ติดต่อซัพพลายเออร์',
    LANG_SEND_MESSAGE_TO_THIS_SUPPLIER:'ส่งข้อความของคุณไปยังซัพพลายเออร์รายนี้',
    LANG_NAME:'ชื่อ',
    LANG_EMAIL:'อีเมล',
    LANG_MOBILE:'มือถือ',
    LANG_SUBJECT:'เรื่อง',
    LANG_MESSAGE:'ข้อความ',
    LANG_SMALL_MSG:'ข้อความของคุณต้องมีความยาวระหว่าง 20-8000 ตัวอักษร',
    LANG_INSTRUCTION:'สำหรับใบเสนอราคาที่ดีกว่า รวม',
    LANG_INSTRUCTION1:'สำหรับใบเสนอราคาที่ดีกว่า รวม',
    LANG_INSTRUCTION2:'คำขอพิเศษ ถ้ามี',
    LANG_SEND_ENQ:'ส่งคำถาม',
    LANG_SUPPLIER:'ผู้ผลิต',
    LANG_FREE:'ฟรี',
    LANG_GOLD:'ทอง',
    LANG_SILVER:'เงิน',
    LANG_PLATINUM:'แพลตตินั่ม',
    LANG_SOCIAL_LINK:'ลิงค์โซเชียล',
    LANG_POPULAR_STORE_PRODUCTS : 'สินค้าร้านค้ายอดนิยม',
    LANG_SHIPPING:'จัดส่งฟรี',
    LANG_INSTRUCTION3:'คำสั่งซื้อที่สูงกว่า $100',
    LANG_RETURN:'ผลตอบแทนฟรี',
    LANG_INSTRUCTION4:'คืนสินค้าภายใน 30 วัน',
    LANG_INSTRUCTION5:'การชำระเงินที่ปลอดภัย 100%',
    LANG_SECURE_PAYMENT:'การชำระเงินที่ปลอดภัย',
    LANG_BEST_PEICE:'ราคาที่ดีที่สุด',
    LANG_GURANTEED_PRICE:'รับประกันราคา',
    LANG_STORE_PRODUCTS:'จัดเก็บผลิตภัณฑ์',
    LANG_CATEGORIES:'หมวดหมู่',
    LANG_RECORD_NOT_FOUND:'ไม่พบบันทึก',
    LANG_INFORMATION:'ข้อมูล',
    LANG_ABOUT_US:'เกี่ยวกับเรา',
    LANG_FAQ:'คำถามที่พบบ่อย',
    LANG_T_AND_C:'ข้อตกลงและเงื่อนไข',
    LANG_CONTACT_US:'ติดต่อเรา',
    LANG_CUSTOMER_SERVICE:'บริการลูกค้า',
    LANG_PAYMENT_METHODS:'วิธีการชำระเงิน',
    LANG_MONEY_BACK:'เงินกลับ',
    LANG_RETURNS:'คืนสินค้า',
    LANG_PRIVACY_POLICY:'นโยบายความเป็นส่วนตัว',
    LANG_GET_IN_TOUCH:'รับใน Tuch',
    LANG_TEL:'โทร',
    LANG_COPYRIGHT:'ลิขสิทธิ์',
    LANG_ALL_RIGHT_RESERVED:'สงวนลิขสิทธิ์',

    LANG_FEEDBACK:'ข้อเสนอแนะ',
    LANG_CUSTOMERFEEDBACK:'ความคิดเห็นของลูกค้า',
    LANG_FULLNAME:'ชื่อเต็มของคุณ',
    LANG_STAR:'ระดับดาว',
    LANG_MEAAGE_DIC:'คุณกำลังคิดอะไรอยู่?',

    LANG_COLLABORATE_WITH_US:'ร่วมงานกับเรา',
    LANG_TERMS_OF_USE:'ข้อกำหนดการใช้งาน',
    LANG_SITEMAP:'แผนผังเว็บไซต์',
}                         

export default th;