import React ,{useState,useEffect}from 'react'
import Axios from 'axios';
import global from './config/global';

import Header from './includes/header';
import Categorys from './Categorys';
import Footer from './includes/footer';
// import ContentLoader from 'react-content-loader'
import queryString from 'query-string';
import i18next,{ t } from 'i18next';
import {convertCurrency} from './config/currency';
import { NumericFormat } from 'react-number-format';




const Products = (props) => {
  const [state,setState] = useState({
    productListData:[],
    isLoading:true,
});
const [getcurrencydata,setgetcurrencydata] = useState('');
const [currencySyb,setcurrencySyb] = useState('');


useEffect(()=>{
  var params = queryString.parse(window.location.search); 
  
        if(params)
        {
            if(params.cat_id)
            {
                let cat_id = params.cat_id;
                stateHandler('cat_id',cat_id)
            }
            else
            {
                let pro_name = params.pro_name;
                stateHandler('pro_name',pro_name)
            }
            
        }
    fetchProductsData();
    
},[]);

const stateHandler = (key,value) =>{
    state[key] = value;
    setState({...state});
}
const getProductPriceRange = (product_price) => {
  var minprice = 0;
  // var maxprice = 0;
  if(product_price)
  {
    for(let value of product_price)
    {
      let selling = value.price - (value.price * value.discount) / 100;
      if(minprice > selling || minprice == 0)
      {
        minprice = selling;
      }
    }
  }
  if(props.home==true){
    return convertCurrency(`${minprice.toFixed(2)}`,props.curr_data);
  }else{
    return convertCurrency(`${minprice.toFixed(2)}`,getcurrencydata);
  }
}


const fetchProductsData = () => {
  
    let hostname = window.location.hostname;
    let spliteHost = hostname.split('.');
    let domain = '';
    if(spliteHost.length === 3 && spliteHost[0] !== 'www')
    {
        domain = spliteHost[0];
    }

let body = {
  domain:domain,
        cat_id:state.cat_id,
        pro_name:state.pro_name,
        defaultLang:i18next.language
}
Axios.post(`${global.BASEURL}micro/fetchMicroSiteProducts`,body).then((res) => {
  if (res.data.status === 200) {
    let response = res.data.data;
            stateHandler('productListData',response)
  }
        stateHandler('isLoading',false)
}).catch((err) => {
        stateHandler('isLoading',false)
  console.log(err);
})

}

const truncate = (str) => {
return str.length > 10 ? str.substring(0, 20) + "..." : str;
}

const getRealPrice = (product_price) => {		
var minprice = 0;
if(product_price)
{
  for(let value of product_price)
  {
    let selling = value.price - (value.price * value.discount) / 100;
    if(minprice > selling || minprice == 0)
    {
      minprice = selling;
    }
  }
}

return `${minprice.toFixed(2)}`;
}

const getFirstOtherAttrId = (product) => {
  let other_pro_id = 0;
  var minprice = 0;
  if(product)
  {
    for(let value of product)
    {
      let selling = value.price - (value.price * value.discount) / 100;
      if(minprice > selling || minprice == 0)
      {
        other_pro_id = value.other_pro_id;
        minprice = selling;
      }
    }
  }
  
  return other_pro_id;
}

  return(
    <>
    {props.home !==true && <Header get_data={(Syb,data)=>{
      setgetcurrencydata(data);
      setcurrencySyb(Syb);
   }}/>
   }
    <section className="pb-5 bg-light">
    <div className="container pt-3">
      <section className="">
        <div className="">
          <div className="row">
            <h5>{t('LANG_STORE_PRODUCTS')}</h5>
            <div className="col-12 col-md-2 bg-white pt-4 mb_display_none" id="">
              <h5><i className="material-icons text-main">list</i> <span className="">{t('LANG_CATEGORIES')}</span></h5>
              <Categorys/>
            </div>
            <div className="col-12 col-md-10">
              <div className="row">
              {state.productListData.length>0 ?
              state.productListData.map((value,index)=>(
                <div key={index} className="col-6 col-md-3">
                  <a arget="_blank" href={`${global.SITEURL}productDetail/${value.prod_id}/${getFirstOtherAttrId(value.pricedata)}`}>
                  <div className="card">
                    <div className="card-body">
                      <figure className="mb-2">
                        <img src={`${global.PRODUCT_IMAGE}640X640/${value.product_image_path}`} alt={value.pro_name} className="img-fluid" />
                      </figure>
                      <p className="">
                        {truncate(value.pro_name)}
                      </p>
                      <div className="d-flex justify-content-between">
                          <div className="p-2">
                            {props.home==true ?
                              // <h6 className="text-main">{props.syb} {getProductPriceRange(value.pricedata)}</h6>
                              <NumericFormat
                                  value={getProductPriceRange(value.pricedata)}
                                  thousandsGroupStyle="thousand"
                                  thousandSeparator=","
                                  prefix={props.syb}
                                  displayType="text"
                                  renderText={(value) => <h6>{value}</h6>}
                              />
                              :
                              // <h6 className="text-main">{currencySyb} {getProductPriceRange(value.pricedata)}</h6>
                              <NumericFormat
                                  value={getProductPriceRange(value.pricedata)}
                                  thousandsGroupStyle="thousand"
                                  thousandSeparator=","
                                  prefix={currencySyb}
                                  displayType="text"
                                  renderText={(value) => <h6>{value}</h6>}
                              />
                            }
                          </div>
                          {/* <div className="p-2 ">
                            <small className="text-muted">2k Sold</small>
                          </div> */}
                      </div>
                    </div>
                  </div>
                  </a>
                </div>
                 ))
                 :
                 <div className="col-12 text-center">
                  <span className='text-danger text-center h3'>{t('LANG_RECORD_NOT_FOUND')}</span></div>
               }
              </div>
            </div>
          </div>      
        </div>
      </section>
    </div>
  </section>
  {props.home !==true && <Footer />}
  </>
    );
}

export default Products;
