import en from '../../languages/english';
import hn from '../../languages/hindi';
import fr from '../../languages/french';
import ce from '../../languages/chinese';
import jp from '../../languages/Japanese';
import kr from '../../languages/korean';
import gr from '../../languages/german';
import la from '../../languages/lao';
import th from '../../languages/thai';

 
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: en
    },
    hn: {
      translations: hn
    },
    fr: {
      translations: fr
    },
    jp: {
      translations: jp
    },
    kr: {
      translations: kr
    },
    gr: {
      translations: gr
    },
    ce: {
      translations: ce
    },
    lao: {
      translations: la
    },
    th: {
      translations: th
    }
  },
  fallbackLng: "en",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;