const fr = {
    LANG_ABOUT:'À propos du magasin',
    LANG_CONTACT:'Contacter le magasin',
    LANG_HELP:'Aider',
    LANG_IN_THIS_STORE:'Dans ce magasin',
    LANG_ON_SELLSBUY:'Sur Vendsbuy',
    LANG_FOLLOW:'Suivre',
    LANG_UNFOLLOW:'Ne plus suivre',
    LANG_CHAT:'Discuter',
    LANG_PRODUCTS:'Des produits',
    LANG_COMPANY_VERIFY:"Vérification de l'entreprise",
    LANG_CHAT_PERFORMANCE:'Performances de chat',
    LANG_FOLLOWERS:'Suiveuses',
    LANG_RATINGS:'Notes',
    LANG_JOINED:'Rejointe',
    LANG_MONTHS_AGO:'Il y a des mois',
    LANG_HOME:'Maison',
    LANG_ALL_PRODUCTS:'Tous les produits',
    LANG_CONTACT_SUPPLIERS:'Contacter les fournisseurs',
    LANG_SEND_MESSAGE_TO_THIS_SUPPLIER:'Envoyez votre message à ce fournisseur',
    LANG_NAME:'Nom',
    LANG_EMAIL:'E-mail',
    LANG_MOBILE:'Mobile',
    LANG_SUBJECT:'Matière',
    LANG_MESSAGE:'Message',
    LANG_SMALL_MSG:'Votre message doit contenir entre 20 et 8000 caractères',
    LANG_INSTRUCTION:'Pour de meilleurs devis, incluez',
    LANG_INSTRUCTION1:'Pour de meilleurs devis, incluez',
    LANG_INSTRUCTION2:'Demandes spéciales, le cas échéant',
    LANG_SEND_ENQ:'envoyer une demande',
    LANG_SUPPLIER:'Le fournisseur',
    LANG_FREE:'Libre',
    LANG_GOLD:'Or',
    LANG_SILVER:'Argent',
    LANG_PLATINUM:'Platine',
    LANG_SOCIAL_LINK:'Lien social',
    LANG_POPULAR_STORE_PRODUCTS : 'Produits populaires du magasin',
    LANG_SHIPPING:'LIVRAISON GRATUITE',
    LANG_INSTRUCTION3:'Commande supérieure à 100 $',
    LANG_RETURN:'RETOUR GRATUIT',
    LANG_INSTRUCTION4:'Retours sous 30 jours',
    LANG_INSTRUCTION5:'Paiement 100% sécurisé',
    LANG_SECURE_PAYMENT:'PAIEMENT SÉCURISÉ',
    LANG_BEST_PEICE:'MEILLEUR PRIX',
    LANG_GURANTEED_PRICE:'Prix ​​garanti',
    LANG_STORE_PRODUCTS:'Produits du magasin',
    LANG_CATEGORIES:'Catégories',
    LANG_RECORD_NOT_FOUND:'Enregistrement non trouvé',
    LANG_INFORMATION:'Informations',
    LANG_ABOUT_US:'À propos de nous',
    LANG_FAQ:'FAQ',
    LANG_T_AND_C:'termes et conditions',
    LANG_CONTACT_US:'Nous contacter',
    LANG_CUSTOMER_SERVICE:'Service Clients',
    LANG_PAYMENT_METHODS:'méthodes de payement',
    LANG_MONEY_BACK:'Remboursement',
    LANG_RETURNS:'Retour',
    LANG_PRIVACY_POLICY:'Politique de confidentialité',
    LANG_GET_IN_TOUCH:'Entrez en contact',
    LANG_TEL:'Tél',
    LANG_COPYRIGHT:'Droits d auteur',
    LANG_ALL_RIGHT_RESERVED:'Tous les droits sont réservés',

    LANG_FEEDBACK:'Retour',
    LANG_CUSTOMERFEEDBACK:'Commentaires des clients',
    LANG_FULLNAME:'Ton nom complet',
    LANG_STAR:'Évaluation étoilée',
    LANG_MEAAGE_DIC:'Quest-ce qui préoccupe votre esprit?',

    LANG_COLLABORATE_WITH_US:'Collaborez avec nous',
    LANG_TERMS_OF_USE:'Conditions dutilisation',
    LANG_SITEMAP:'Plan du site',
    
}                         

export default fr;