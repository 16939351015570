import React ,{useState,useEffect}from 'react'
import Axios from 'axios';
import global from './config/global';
import i18next from 'i18next';
import { t } from 'i18next';

const Categorys = (props) => {
  const [state,setState] = useState({
    categoryList:{}
});

const [hovercolor,sethovercolor] = useState();

useEffect(()=>{
    fetchCategoryList()
},[]);

const stateHandler = (key,value) =>{
    state[key] = value;
    setState({...state});
}
let hostname = window.location.hostname;
let spliteHost = hostname.split('.');
let domain = '';
if(spliteHost.length === 3 && spliteHost[0] !== 'www')
{
    domain = spliteHost[0];
}
const fetchCategoryList = (index) => {
let body = {
  domain:domain,
}
Axios.post(`${global.BASEURL}micro/fetchVendorsubProductGroupList`,body).then((res) => {
  if (res.data.status === 200) {
    let response = res.data.data;
            stateHandler('categoryList',response)
  }
  else {
            stateHandler('isLoading',false)
  }
}).catch((err) => {
  console.log(err);
})

}
 const getactive=(path)=> {
  let pathname = window.location.href;
  let current_path = '/'+pathname.split('/').pop();
  if (path == current_path) {
    return 'active';
  }
  else {
    return {};
  }
}

  return(
              <ul className="nav flex-column mic_shop_left_menu " >
                <li className="nav-item">
                <a className={`nav-link ${getactive(`/products`)}`}  href="/products">{t('LANG_ALL_PRODUCTS')}</a>
                </li>
                {state.categoryList.length>0 ?
                state.categoryList.map((data)=>{
                  return(
                  <li className="nav-item" key={data.id}>
                  <a className={`nav-link ${getactive(`/products?cat_id=${data.id}`)}`} href={`/products?cat_id=${data.id}`}>{data.product_group_name}</a>
                </li>
                  )
                })
                :
                  <></>  
                }
                
            </ul>
      
    );
}

export default Categorys;
