import React, { useState, useEffect}from 'react'
import Header from './includes/header';
import Footer from './includes/footer';
import Axios from 'axios';
import global from './config/global';
import { Link } from 'react-router-dom';

function CmsPage(props){

    const [	state,setState] = useState({});
	const [	cmstype,setcmstype] = useState({});

    useEffect(()=>{
		fetchVendorData()
	},[]);

    let hostname = window.location.hostname;
	let spliteHost = hostname.split('.');
	let domain = '';
	if(spliteHost.length === 3 && spliteHost[0] !== 'www')
	{
	  domain = spliteHost[0];
	}

    const fetchVendorData = (index) => {
		let body = {
			domain:domain,
		}
		Axios.post(`${global.BASEURL}micro/fetchVendorData`,body).then((res) => {
			if (res.data.status === 200) {
				let response = res.data.data;
                let vendor_id = res.data.data.vender_id;
                setState(response)
                fetchfooterdata(vendor_id)
			}
		}).catch((err) => {
			console.log(err);
		})

	}

    const fetchfooterdata = (vender_id) =>
	{
        const queryParams = new URLSearchParams(window.location.search);
        const type = queryParams.get('type');
        let body = {
            id:vender_id,
            type:type
        }
        Axios.post(`${global.M_BASEURL}micro/fetchfooterdata`,body).then((res) => {
            if (res.data.status === 200) {
                let response = res.data.data[0];
                setcmstype(response)
            }
        }).catch((err) => {
            console.log(err);
        })
	}
    return(
        <div>
            <Header />
            <div className="container">
                <div className='col-12'>
                    <div className='row'>
                        <div className='col-12'>
                        <div align="center">
                            {/* <h2>{data.title}</h2> */}
                         </div>
                         {cmstype.Faq &&
                            <div dangerouslySetInnerHTML={{ __html: cmstype.Faq }}></div>
                         }
                         {cmstype.Terms_Conditions &&
                            <div dangerouslySetInnerHTML={{ __html: cmstype.Terms_Conditions }}></div>
                         }
                         {cmstype.Payment_Methods &&
                            <div dangerouslySetInnerHTML={{ __html: cmstype.Payment_Methods }}></div>
                         }
                         {cmstype.Money_back &&
                            <div dangerouslySetInnerHTML={{ __html: cmstype.Money_back }}></div>
                         }
                         {cmstype.Returns &&
                            <div dangerouslySetInnerHTML={{ __html: cmstype.Returns }}></div>
                         }
                         {cmstype.Shipping &&
                            <div dangerouslySetInnerHTML={{ __html: cmstype.Shipping }}></div>
                         }
                         {cmstype.Privacy_Policy &&
                            <div dangerouslySetInnerHTML={{ __html: cmstype.Privacy_Policy }}></div>
                         }
                        </div>
                    </div>
                </div>
            </div>
                
            <Footer />
        </div>           
	);
	
}
export default CmsPage;
