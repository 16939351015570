import { createStore ,applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import AsyncStorage from '@react-native-community/async-storage';
import { composeWithDevTools } from 'redux-devtools-extension';

import loggedReducer from './islogged';
import { combineReducers } from 'redux';

const rootPersistConfig = {
    key: 'root',
    storage:AsyncStorage,
    blacklist: ['Login']
}

const allReducers = combineReducers({
    userid:loggedReducer
});

const persistedReducer = persistReducer(rootPersistConfig, allReducers);
let store = createStore(persistedReducer,composeWithDevTools(applyMiddleware(thunk)));
let persistor = persistStore(store);

export { store, persistor };