import React, { useState, useEffect } from 'react'
import Categorys from '../Categorys';
import global from '../config/global';
import Axios from 'axios';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import {Currency,convertCurrency} from '../config/currency';
import getSymbol from '../config/symbol';
import i18next, { use } from 'i18next';
import { useSelector,useDispatch } from 'react-redux';
import { userid } from '../../action/index';
const Header = (props) =>{

  const { t, i18n } = useTranslation();
  const defaultLang = (i18n.language.indexOf('en') != -1) ? 'en' : i18n.language;

  const dispatch=useDispatch();
  const get_id =useSelector(state=>state.userid.userid);
  const [cartitem, setcartitem] = useState('0');
  const [social, setsocial] = useState({});
  const [vendordata, setvendordata] = useState({});
  const [dateofjoin, setdateofjoin] = useState();
	const [colors, setcolors] = useState({});
	const [totalproduct, settotalproduct] = useState('');
	const [totalfollowers, settotalfollowers] = useState('');
	const [menuColor1000, setmenuColor1000] = useState('');
	const [menuColor2000, setmenuColor2000] = useState('');
	const [menuColor1, setmenuColor1] = useState('');
	const [menuColor2, setmenuColor2] = useState('');
	const [menuColor3, setmenuColor3] = useState('');
	const [menuColor4, setmenuColor4] = useState('');
	const [menuColor5, setmenuColor5] = useState('');
	const [menuColor6, setmenuColor6] = useState('');
	const [menuColor7, setmenuColor7] = useState('');
	const [isloading, setisloading] = useState(true);
	const [sitename, setsitename] = useState('');
	const [categorys, setcategorys] = useState('');
	const [search_product, setsearch_product] = useState('');
	const [chat_performance, setchat_performance] = useState('');
	const [storeRating, setstoreRating] = useState({ count_review: 0, positive_feedback: 0 });
	const [vendercompanyyear, setvendercompanyyear] = useState(0);
  const [storeData, setstoreData] = useState(false);
  const [chat_performance_precent, setchat_performance_precent] = useState(0);
  const [chat_performance_timing, setchat_performance_timing] = useState(0);
  const [lang, setlang] = useState('en');
  const [currencydata,setcurrencydata] = useState(global.DEFAULT_CURCY);
  const [getcurrencydata,setgetcurrencydata] = useState('');
  const [currencySyb,setcurrencySyb] = useState(getSymbol(global.DEFAULT_CURCY));
  const [checkonline,setcheck_online] = useState('');
  const [backgroundImage,setbackgroundImage] = useState('');
  const [company_logo, setcompany_logo] = useState([]);
  
		useEffect(() => { 
          let hostname = window.location.hostname;
          let spliteHost = hostname.split('.');
          let domain = '';
          if (spliteHost.length === 3 && spliteHost[0] !== 'www') {
            domain = spliteHost[0];
          }
          if(window.location.search.split('=')[0]=="?id" && window.location.search.split('=')[1]!='undefined'){
            dispatch(userid(window.location.search.split('=')[1]));
          }
          
          fetchVendorData(domain)
          fetchColors(domain)
          fetchVendorTotalproduct(domain)
          fetchtotalfollowers(domain)
          fetchCategoryList(domain)
          componentDidUpdate(domain)
          fetchbuyercartitem()
          fetchCompanyInfo()
          const get_data = props.get_data;
          if(get_data){
            let Syb = currencySyb;
            let data = currencydata;
            get_data(Syb,data);
          }
        }, [],);
        const [state,setState] = useState({
          lang:defaultLang,
          language:global.langList,
          currency:currencydata,
        });
        const stateHandler = (key,value) =>{
          state[key] = value;
          setState({...state});
      }
      const changeHeaderConfig = (e)=>{
        setlang(e);
        i18n.changeLanguage(e);
      window.location.reload();
      }
      const changeHeaderCurrency=(e)=>{
        setcurrencydata(e);
        Currency(e).then((res)=>{
          setgetcurrencydata(res);
          let curr_symb =  getSymbol(res.Code);
          setcurrencySyb(curr_symb);
          const get_data = props.get_data;
          if(get_data){
            let Syb = curr_symb;
            let data = res;
            get_data(Syb,data);
          }
        });
      }

      const fetchCompanyInfo = () => {

        Axios.get(`${global.A_BASEURL}setting/fetchCompanyInfo`).then((res) => {
          if (res.data.status == 200) {
            let response = res.data.data;
            setcompany_logo(response.logo);
          }
        }).catch((err) => {
          console.log(err)
        })
      }
      const fetchColors=(domain)=>{
        setsitename(domain);
          const getbannerImg = props.getbannerImg;
          let body = {
            domain: domain,
          }
          Axios.post(`${global.BASEURL}micro/fetchMicroDataBysubdomain`, body).then((res) => {
            if (res.data.status === 200) {
              let response = res.data.data;
              setcolors(response);
              if(response.headerimg!="" & response.headerimg!=null & response.headerimg!=undefined){
                setbackgroundImage(`https://cdn.sellsbuy.com/microsite_thumbnail/${colors.headerimg}`)
           }else{
              setbackgroundImage(`https://i.imgur.com/OhefgwT.png`)
           }
              if (getbannerImg) {
                let banner = `${global.MICROBANNER}${response.banner}`;
                let banner_2 = `${global.MICROBANNER}${response.banner_2}`;
                let banner_3 = `${global.MICROBANNER}${response.banner_3}`;
                let caption = `${response.img_caption}`;
                let link = `${response.img_link}`;
                getbannerImg(banner,banner_2,banner_3,caption,link)
              }
            }
            setisloading(false);
          }).catch((err) => {
            setisloading(false);
            console.log(err);
          })
        }
        const fetchVendorData = (domain) => {

          let body = {
            domain: domain,
          }
          Axios.post(`${global.BASEURL}micro/fetchVendorData`, body).then((res) => {
            if (res.data.status === 200) {
              let response = res.data.data;
              setvendordata(response)
              getdateofjoin(response.entry_date);
              calculate_age(response.company_year);
              getStoreRating(response.id, response.store_unique_id);
              get_chat_performance(response.uid);
              setsocial(response.social);
              checkfollow(response.id);
              updateViewCount(response.vender_id);
            }
          }).catch((err) => {
            console.log(err);
          })
        }
        const updateViewCount=(vender_id)=>{
          let body = {
            vender_id: vender_id,
          }
          Axios.post(`${global.W_BASEURL}front/supplier/updateViewCount`, body).then((res) => {
            if (res.data.status === 200) {
              let response = res.data.data;
            }
          }).catch((err) => {
            console.log(err);
          })
        }
        const getStoreRating = (user_id, store_id) => {
          Axios.get(global.BASEURL + `users/getStoreRating/${user_id}/${store_id}`).then((res) => {
              if (res.data.status === 200) {
                  let response = res.data.data[0];
                  setstoreRating(response);
              }
          }).catch((err) => {
              console.log(err);
          })
      }
      const getdateofjoin=(date)=>{
        let joineddate=date.split('T')[0];
        const [year, month, day] =  joineddate.split('-')
        setdateofjoin(`${day}-${month}-${year}`);
      }
      const checkfollow = (vendor_id) =>{
        let body = {
            userid: get_id,
            store_id:vendor_id
          }
          Axios.post(`${global.M_BASEURL}micro/checkfollow`, body).then((res) => {
            if (res.data.status === 200) {
              let response = res.data.data;
                setstoreData(response);
              }
          }).catch((err) => {
            console.log(err);
          })
      }
      const fetchVendorTotalproduct = (domain) => {

          let body = {
            domain: domain,
            lang:defaultLang,
          }
          Axios.post(`${global.BASEURL}micro/totalproduct`, body).then((res) => {
            if (res.data.status === 200) {
              let response = res.data.data.product;
              settotalproduct(response)
            }
          }).catch((err) => {
            console.log(err);
          })

        }
      const fetchtotalfollowers = (domain) => {

          let body = {
            domain: domain,
          }
          Axios.post(`${global.BASEURL}micro/totalfollowers`, body).then((res) => {
            if (res.data.status === 200) {
              let response = res.data.data.followers;
              settotalfollowers(response)
            }
          }).catch((err) => {
            console.log(err);
          })
      
        }
      const fetchCategoryList = (domain) => {
          let body = {
            domain:domain,
            type:'header'
          }
          Axios.post(`${global.BASEURL}micro/fetchVendorsubProductGroupList`,body).then((res) => {
            if (res.data.status === 200) {
              let response = res.data.data;
              setcategorys(response)
            }
          }).catch((err) => {
            console.log(err);
          })
      
        }
      const componentDidUpdate=(domain)=>{
          const favicon = document.getElementById("favico");
          favicon.href = `${global.COMPANY_LOGO}/${vendordata.logo}`;
          const title = document.getElementById("title");
          title.innerHTML = domain;
        }
      const redirectToChat = () =>{
          window.open(`${global.qiiko_CHAT_URL}${vendordata.uid}`,'_blank');
        }
      const mouseOver=(key, value)=>{
          if (value) {
            setcolors({key:value});
          }
          else {
            setcolors({key:' '});
          }
      
        }
      
      const getColor=(key)=>{
      
          if (colors[key]) {
            return { backgroundColor: colors[key] };
          }
          else {
            return {};
          }
        }
      const getactive=(path)=>{
          let pathname = window.location.href;
          let current_path = '/'+pathname.split('/').pop();
          if (path == current_path) {
            return { backgroundColor:colors.color1 };
          }
          else {
            return {};
          }
        }
      const calculate_age=(company_year)=>{
          var current_year = new Date().getFullYear();
          var com_now = current_year - company_year;
          setvendercompanyyear(com_now);
        }

      const get_chat_performance=(user_id)=>{
        let header={
          'Content-Type':'application/json'
        }
          let body = {
            uid:user_id
          }
          Axios.post("https://sellsbuy-messenger-backend.sellsbuy.com/v.1.0/web/query/user/profile_open",body,{Headers:header}).then((res)=>{
          if(res.data.status == 200){
              setchat_performance_precent(res.data.output[0].chat_reponse_percentage);
              setchat_performance_timing(res.data.output[0].chat_timing_percentage);
              setcheck_online(res.data.output[0].last_seen);
            }
        })
      }

    const addToFollow = (vender_id) => {
        let follower_id = get_id;
        if(follower_id!=null){
          let body = {
            user_id: follower_id,
            store_id: vender_id,
          };
          Axios.post(global.W_BASEURL+'buyer/followers/addFollowList',body).then((res)=>{
          if (res.data.status === 200) {
            let response = res.data.data;
              fetchtotalfollowers(vendordata.sub_domain);
              if(storeData==true){
                setstoreData(false);
              }else{
                setstoreData(true);
              }
          }
          }).catch((err) => {
            console.log(err);
          })
        }
      }
      const fetchbuyercartitem =()=> {
        let buyer_id = get_id;
        if(buyer_id!=null){
          let body = {
            id:buyer_id,
          };
          Axios.post(global.W_BASEURL+'buyer/cart/cartcount',body).then((res)=>{
          if (res.data.status === 200) {
            let response = res.data.data;
            setcartitem(response.count);

          }
          }).catch((err) => {
            console.log(err);
          })
        }
      }
      const loginalert=()=>{
        window.location = 'https://www.sellsbuy.com/buyer/login';
      }
      const getratingprecentage=(star,reviews)=>{
        var precentage=0;
        if(star!=null){
          precentage=star/reviews;
        }
        return precentage.toFixed(1);
        
      }
	  return (
      <header className="header shop">
        {isloading &&
					<div className="preloader">
						<div className="preloader-inner">
							<div className="preloader-icon">
								<span></span>
								<span></span>
							</div>
						</div>
					</div>
				}
    <section className="" style={{ backgroundColor: colors.color2}}>
      <div className="container">
        <section className="fs-14">
          <div className="">
            <div className="row g-0">
              <div className="col-5 col-md-6">
                <ul className="nav">
                    <li className="nav-item">
                      <a className="nav-link" href={`tel:${vendordata.vender_mobile}`}><i style={{ color: colors.color1 }} className="material-icons-outlined">call</i> <span style={{ color: colors.color1 }}>{vendordata.vender_mobile}</span></a>
                    </li>
                    <li className="nav-item mb_display_none">
                      <a className="nav-link" href={`mailto:${vendordata.vender_email}`}><i style={{ color: colors.color1 }} className="material-icons-outlined">email</i> <span style={{ color: colors.color1 }}>{vendordata.vender_email}</span></a>
                    </li>
                    
                        {social.facebook_onoff=='Y' && 
                          <li className="nav-item mb_display_none">
                            <a className="nav-link" target='blank' href={social.facebook_info}><i className="fa fa-facebook" style={{fontSize:'20px',color: colors.color1}}></i></a>
                          </li>
                        }
                        {social.whatsapp_onoff=='Y' && 
                          <li className="nav-item mb_display_none">
                            <a className="nav-link" target='blank' href={`https://api.whatsapp.com/send?phone=${social.whatsapp_info}`}><i className="fa fa-whatsapp" style={{fontSize:'20px',color: colors.color1}}></i></a>
                          </li>
                        }
                        {social.skype=='Y' && 
                          <li className="nav-item mb_display_none">
                            <a className="nav-link" target='blank' href={`https://www.skype.com/${social.skype_info}`}><i className="fa fa-skype" style={{fontSize:'20px',color: colors.color1}}></i></a>
                          </li>
                        }
                    
                  </ul>
              </div>
              <div className="col-7 col-md-6">
                <ul className="nav justify-content-end">
                  <li className="nav-item mb_display_none">
                    <a className="nav-link" href="/aboutus"> <span style={{ color: colors.color1 }}>{t('LANG_ABOUT')}</span></a>
                  </li>
                  <li className="nav-item mb_display_none">
                    <a className="nav-link" href="/contactus"><span style={{ color: colors.color1 }}>{t('LANG_CONTACT')}</span></a>
                  </li>
                  <li className="nav-item mb_display_none">
                    <a className="nav-link" target={'_blank'} href="https://www.sellsbuy.com/contactus"><span style={{ color: colors.color1 }}>{t('LANG_HELP')}</span></a>
                  </li>
                  <li className="nav-item dropdown">
                    <select defaultValue={i18n.language} className="form-select form-select-sm" onChange={({target})=>{changeHeaderConfig(target.value)}}>
													{global.langList.map((value)=>(
														<option key={value.label} value={value.value}>{value.label}</option>
													))}
												</select>
                  </li>
                  <li className="nav-item dropdown">
                    <select defaultValue={getcurrencydata.Code} className="form-select form-select-sm" onChange={({target})=>{changeHeaderCurrency(target.value)}}>
													{global.currencyList.map((value)=>(
														<option key={value.label} value={value.value}>{value.label}</option>
													))}
												</select>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      </section>
      <section className="mic_sub_header mt-3">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-5 mb_text_center mb_mb-5">
              <a href="https://sellsbuy.com/"><img src={`${global.LOGO_PATH}${company_logo}`} className="img-fluid mic_logo " /></a>
            </div>
            <div className="col-12 col-md-6">
              <div className="input-group mb-3 input-group-sm mic_top_searchbar">
                <input type="text" onChange={({target})=>{setsearch_product(target.value)}} className="form-control mb_width_100" placeholder="Search something.." />
                <a href={`/products?pro_name=${search_product}`} style={{ backgroundColor: colors.color2 }} className="btn btn-warning m-2" type="button">{t('LANG_IN_THIS_STORE')}</a>
                <a href={`https://sellsbuy.com/products?SearchText=${search_product}`} target='blank' style={{ backgroundColor: colors.color1 }} className="btn btn-success m-2" type="button">{t('LANG_ON_SELLSBUY')}</a>
              </div>
            </div>
            <div className="col-12 col-md-1 mb_text_center mb_mb-5">
              <a className="nav-link" href='https://www.sellsbuy.com/buyer/cart'><i className="fa fa-shopping-cart bold" style={{fontSize:'32px',color: colors.color1}}></i> 
              <span className="position-absolute translate-middle badge rounded-pill" style={{backgroundColor: colors.color1,color: colors.color2,marginTop:'-5px'}}>{cartitem}</span>
              </a>
            </div>
          </div>
        </div>
      </section>

    </div>
  </section>
  <section className="mb-3 mt-3 mic_main_header">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="mic_head_shop_logo_box p-2" style={{ backgroundImage:backgroundImage }} >
          <div className="d-flex">
              <div className="p-2">
                <a href={`http://${vendordata.sub_domain}.sellsbuy.com`}>
                  <img src={`${global.COMPANY_LOGO}/${vendordata.logo}`} className="img-fluid rounded-circle" />
                </a>
              </div>
              <div className="p-2 mt-2">
                <h6>{vendordata.company_name}</h6>
                { checkonline ==="online" ?
                <small className="text-success"><i className="fa fa-circle" aria-hidden="true"></i> online</small>
                :
                <small className="text-muted">Active {(Math.floor(checkonline/1000)%60)} Mins Ago</small>
                }     
              </div>
            </div>
            <div className="d-flex">
                <div className="p-2 flex-fill">
                  <div className="d-grid">
                  {get_id !=null && storeData ==false ?
                      <button type="button" onClick={() => { addToFollow(vendordata.id) }} className="btn btn-primary btn-block"><i className="material-icons-outlined">add</i> {t('LANG_FOLLOW')}</button>
                    :
                    get_id ==null && storeData ==false ?
                    <button type="button" className="btn btn-primary btn-block" onClick={() => {loginalert()}}><i className="material-icons-outlined">add</i> {t('LANG_FOLLOW')}</button>
                    :
                    <button type="button" onClick={() => { addToFollow(vendordata.id) }} className="btn btn-primary btn-block"> {t('LANG_UNFOLLOW')}</button>
                  }
                  </div>
                </div>
                <div className="p-2 flex-fill">
                  <div className="d-grid">
                    <button type="button" onClick={() => { redirectToChat() }} className="btn btn-primary btn-block"><i className="material-icons-outlined">chat_bubble_outline</i> {t('LANG_CHAT')}</button>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4 mt-4">
          <div className="d-flex">
            <div className="pt-2"><i className="material-icons-outlined">local_mall</i></div>
            <div className="p-2 ">{t('LANG_PRODUCTS')}: <span className="text-main">{totalproduct}</span></div>
          </div>
          <div className="d-flex">
            <div className="pt-2"><i className="material-icons-outlined">person_add_alt</i></div>
            <div className="p-2 ">{t('LANG_COMPANY_VERIFY')}: <span className="text-main">{vendordata.company_verifi == 'Y' ? <img style={{ height: "20px", width: "20px" }} src={`/micro/assets/images/verify.jpg`} alt="logo" /> : <img style={{ height: "20px", width: "20px" }} src={`/micro/assets/images/notverified.jpg`} alt="logo" />}</span></div>
          </div>
          <div className="d-flex">
            <div className="pt-2"><i className="material-icons-outlined">chat_bubble_outline</i></div>
            <div className="p-2 ">{t('LANG_CHAT_PERFORMANCE')}: <span className="text-main">{chat_performance_precent} (within {chat_performance_timing} hours)</span></div>
          </div>
        </div>
        <div className="col-12 col-md-4 mt-4 margin_top_0">
          <div className="d-flex">
            <div className="pt-2"><i className="material-icons-outlined">people_outline</i></div>
            <div className="p-2 ">{t('LANG_FOLLOWERS')}: <span className="text-main">{totalfollowers}</span></div>
          </div>
          <div className="d-flex">
            <div className="pt-2"><i className="material-icons-outlined">star_outline</i></div>
            <div className="p-2 ">{t('LANG_RATINGS')}: <span className="text-main">{getratingprecentage(storeRating.totalstar,storeRating.totalreview)}%</span></div>
          </div>
          <div className="d-flex">
            <div className="pt-2"><i className="material-icons-outlined">portrait</i></div>
            <div className="p-2 ">{t('LANG_JOINED')}: <span className="text-main">{dateofjoin}</span></div>
          </div>
        </div>
      </div>      
    </div>
  </section>
  <section className="mb-1 shadow-sm border-bottom" style={{ backgroundColor: colors.color2 }}>
    <div className="container mb_navbar">
      <nav className="navbar navbar-expand-sm ">
        <div className="container-fluid">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar"><i className="material-icons">local_mall</i>
            <span className="navbar-toggler-icon">Store Menu </span>
          </button>
          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="navbar-nav mic_head_shop_menu">
            <li className='nav-item' style={getactive('/')} ><a style={getColor('menuColor1000')} className="nav-link" href="/"> <span style={{color:'white'}}> {t('LANG_HOME')} </span></a></li>
            <li className='nav-item' style={getactive('/feedback')} ><a style={getColor('menuColor1000')} className="nav-link" href="/feedback"> <span style={{color:'white'}}> {t('LANG_FEEDBACK')} </span></a></li>
            <li className='nav-item' style={getactive('/products')} ><a style={getColor('menuColor2000')} className="nav-link" href="/products"> <span style={{color:'white'}}>{t('LANG_ALL_PRODUCTS')} </span></a></li>
            {categorys.length>0 ?
              categorys.map((data,index)=>{
                return(
                <li key={index} className='nav-item' style={getactive(`/products?cat_id=${data.id}`)} ><a style={getColor(`menuColor${index}`)} className="nav-link" href={`/products?cat_id=${data.id}`}> <span style={{color:'white'}}>{data.product_group_name}</span></a></li>
              )
            })
              : 
              <></>
            }
            </ul>
          </div>
        </div>
      </nav> 
    </div>
  </section>
			</header>
		);
	}

export default Header;

