const lao = {
    LANG_ABOUT:'ກ່ຽວກັບຮ້ານ',
    LANG_CONTACT:'ຕິດຕໍ່ຮ້ານ',
    LANG_HELP:'ຊ່ວຍເຫຼືອ',
    LANG_IN_THIS_STORE:'ໃນຮ້ານນີ້',
    LANG_ON_SELLSBUY:'ໃນ Sellsbuy',
    LANG_FOLLOW:'ປະຕິບັດຕາມ',
    LANG_UNFOLLOW:'ເຊົາຕິດຕາມ',
    LANG_CHAT:'ສົນທະນາ',
    LANG_PRODUCTS:'ຜະລິດຕະພັນ',
    LANG_COMPANY_VERIFY:'ບໍລິສັດຢັ້ງຢືນ',
    LANG_CHAT_PERFORMANCE:'ປະສິດທິພາບການສົນທະນາ',
    LANG_FOLLOWERS:'ຜູ້ຕິດຕາມ',
    LANG_RATINGS:'ການຈັດອັນດັບ',
    LANG_JOINED:'ເຂົ້າຮ່ວມ',
    LANG_MONTHS_AGO:'ເດືອນກ່ອນ',
    LANG_HOME:'ບ້ານ',
    LANG_ALL_PRODUCTS:'ຜະລິດຕະພັນທັງຫມົດ',
    LANG_CONTACT_SUPPLIERS:'ຕິດຕໍ່ຜູ້ສະຫນອງ',
    LANG_SEND_MESSAGE_TO_THIS_SUPPLIER:'ສົ່ງຂໍ້ຄວາມຂອງທ່ານໄປຫາຜູ້ສະຫນອງນີ້',
    LANG_NAME:'ຊື່',
    LANG_EMAIL:'ອີເມວ',
    LANG_MOBILE:'ມືຖື',
    LANG_SUBJECT:'ວິຊາ',
    LANG_MESSAGE:'ຂໍ້ຄວາມ',
    LANG_SMALL_MSG:'ຂໍ້ຄວາມຂອງທ່ານຕ້ອງມີລະຫວ່າງ 20-8000 ຕົວອັກສອນ',
    LANG_INSTRUCTION:'ສໍາລັບວົງຢືມທີ່ດີກວ່າ, ປະກອບມີ',
    LANG_INSTRUCTION1:'ສໍາລັບວົງຢືມທີ່ດີກວ່າ, ປະກອບມີ',
    LANG_INSTRUCTION2:'ການຮ້ອງຂໍພິເສດ, ຖ້າມີ',
    LANG_SEND_ENQ:'ສົ່ງສອບຖາມ',
    LANG_SUPPLIER:'ຜູ້ສະຫນອງ',
    LANG_FREE:'ຟຣີ',
    LANG_GOLD:'ຄໍາ',
    LANG_SILVER:'ເງິນ',
    LANG_PLATINUM:'Platinum',
    LANG_SOCIAL_LINK:'ການເຊື່ອມໂຍງສັງຄົມ',
    LANG_POPULAR_STORE_PRODUCTS : 'ຜະລິດຕະພັນຮ້ານ Popluar',
    LANG_SHIPPING:'ຈັດສົ່ງຟຣີ',
    LANG_INSTRUCTION3:'ສັ່ງເກີນ $100',
    LANG_RETURN:'ສົ່ງຄືນຟຣີ',
    LANG_INSTRUCTION4:'ພາຍໃນ 30 ມື້ກັບຄືນມາ',
    LANG_INSTRUCTION5:'100% ຮັບປະກັນການຈ່າຍເງິນ',
    LANG_SECURE_PAYMENT:'ການຈ່າຍເງິນທີ່ປອດໄພ',
    LANG_BEST_PEICE:'ລາຄາທີ່ດີທີ່ສຸດ',
    LANG_GURANTEED_PRICE:'ລາຄາຮັບປະກັນ',
    LANG_STORE_PRODUCTS:'ເກັບຮັກສາຜະລິດຕະພັນ',
    LANG_CATEGORIES:'ໝວດໝູ່',
    LANG_RECORD_NOT_FOUND:'ບໍ່ພົບບັນທຶກ',
    LANG_INFORMATION:'ຂໍ້ມູນ',
    LANG_ABOUT_US:'ກ່ຽວ​ກັບ​ພວກ​ເຮົາ',
    LANG_FAQ:'Faq',
    LANG_T_AND_C:'ຂໍ້ກຳນົດ ແລະເງື່ອນໄຂ',
    LANG_CONTACT_US:'ຕິດ​ຕໍ່​ພວກ​ເຮົາ',
    LANG_CUSTOMER_SERVICE:'ບໍ​ລິ​ການ​ລູກ​ຄ້າ',
    LANG_PAYMENT_METHODS:'ວິທີການຊໍາລະເງິນ',
    LANG_MONEY_BACK:'ເງິນຄືນ',
    LANG_RETURNS:'ກັບຄືນ',
    LANG_PRIVACY_POLICY:'ນະໂຍບາຍຄວາມເປັນສ່ວນຕົວ',
    LANG_GET_IN_TOUCH:'ເຂົ້າ​ໄປ​ໃນ Tuch',
    LANG_TEL:'ໂທ',
    LANG_COPYRIGHT:'ລິຂະສິດ',
    LANG_ALL_RIGHT_RESERVED:'ສະຫງວນລິຂະສິດທັງໝົດ',

    LANG_FEEDBACK:'ຄໍາຕິຊົມ',
    LANG_CUSTOMERFEEDBACK:'ຄວາມຄິດເຫັນຂອງລູກຄ້າ',
    LANG_FULLNAME:'ຊື່ເຕັມຂອງເຈົ້າ',
    LANG_STAR:'ການຈັດອັນດັບດາວ',
    LANG_MEAAGE_DIC:'ເຈົ້າ​ກໍາ​ລັງ​ຄິດ​ຫຍັງ​ຢູ່?',

    LANG_COLLABORATE_WITH_US:'ຮ່ວມມືກັບພວກເຮົາ',
    LANG_TERMS_OF_USE:'ເງື່ອນໄຂການນໍາໃຊ້',
    LANG_SITEMAP:'ແຜນຜັງເວັບໄຊທ໌',
    
}                         

export default lao;