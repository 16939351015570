import RouteList from './component/route';
import {BrowserRouter} from "react-router-dom";
import Home from './component/home';
import { createStore } from 'redux';
import {store,persistor} from '../src/reducer/index'
import { Provider } from 'react-redux';


function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <RouteList />
      </Provider>
    </BrowserRouter>
  );
}

export default App;
