import global from './global';
const symbol = require('./currencySYB.json');

const getSymbol = (code) =>{
    
    let symbolValue = symbol[code];
    if(symbolValue)
    {
       return symbolValue;
    }
    else
    {
        return symbol[global.DEFAULT_CURCY];
    }
}

export default getSymbol;