const en = {
    LANG_ABOUT:'About Store',
    LANG_CONTACT:'Contact Store',
    LANG_HELP:'Help',
    LANG_IN_THIS_STORE:'In this store',
    LANG_ON_SELLSBUY:'On Sellsbuy',
    LANG_FOLLOW:'Follow',
    LANG_UNFOLLOW:'UnFollow',
    LANG_CHAT:'Chat',
    LANG_PRODUCTS:'Products',
    LANG_COMPANY_VERIFY:'Company Verify',
    LANG_CHAT_PERFORMANCE:'Chat Performance',
    LANG_FOLLOWERS:'Followers',
    LANG_RATINGS:'Ratings',
    LANG_JOINED:'Joined',
    LANG_MONTHS_AGO:'Months Ago',
    LANG_HOME:'Home',
    LANG_ALL_PRODUCTS:'All Products',
    LANG_CONTACT_SUPPLIERS:'Contact Suppliers',
    LANG_SEND_MESSAGE_TO_THIS_SUPPLIER:'Send your message to this supplier',
    LANG_NAME:'Name',
    LANG_EMAIL:'Email',
    LANG_MOBILE:'Mobile',
    LANG_SUBJECT:'Subject',
    LANG_MESSAGE:'Message',
    LANG_SMALL_MSG:'Your message must be between 20-8000 characters',
    LANG_INSTRUCTION:'For better quotations, include',
    LANG_INSTRUCTION1:'A self introduction',
    LANG_INSTRUCTION2:'Special requests, if any',
    LANG_SEND_ENQ:'Send Inquiry',
    LANG_SUPPLIER:'Supplier',
    LANG_FREE:'Free',
    LANG_GOLD:'Gold',
    LANG_SILVER:'Silver',
    LANG_PLATINUM:'Platinum',
    LANG_SOCIAL_LINK:'Social_link',
    LANG_POPULAR_STORE_PRODUCTS : 'Popluar Store Products',
    LANG_SHIPPING:'FREE SHIPPING',
    LANG_INSTRUCTION3:'Order above $100',
    LANG_RETURN:'FREE RETURN',
    LANG_INSTRUCTION4:'Within 30 days returns',
    LANG_INSTRUCTION5:'100% secure payment',
    LANG_SECURE_PAYMENT:'SECURE PAYMENT',
    LANG_BEST_PEICE:'BEST PEICE',
    LANG_GURANTEED_PRICE:'Guaranteed price',
    LANG_STORE_PRODUCTS:'Store Products',
    LANG_CATEGORIES:'Categories',
    LANG_RECORD_NOT_FOUND:'Record Not Found',
    LANG_INFORMATION:'Information',
    LANG_ABOUT_US:'About Us',
    LANG_FAQ:'Faq',
    LANG_T_AND_C:'Terms & Conditions',
    LANG_CONTACT_US:'Contact Us',
    LANG_CUSTOMER_SERVICE:'Customer Service',
    LANG_PAYMENT_METHODS:'Payment Methods',
    LANG_MONEY_BACK:'Money-back',
    LANG_RETURNS:'Returns',
    LANG_PRIVACY_POLICY:'Privacy Policy',
    LANG_GET_IN_TOUCH:'Get In Tuch',
    LANG_TEL:'Tel',
    LANG_COPYRIGHT:'Copyrights',
    LANG_ALL_RIGHT_RESERVED:'All Rights Reserved',
    
    LANG_FEEDBACK:'Feedback',
    LANG_CUSTOMERFEEDBACK:'Customers Feedback',
    LANG_FULLNAME:'Your Full Name',
    LANG_STAR:'Star Rating',
    LANG_MEAAGE_DIC:'Whats on your mind?',

    LANG_COLLABORATE_WITH_US:'Collaborate with us',
    LANG_TERMS_OF_USE:'Terms of Use',
    LANG_SITEMAP:'Sitemap',
}                         

export default en;